import styled, { css } from "styled-components";
import colors from "../../../assets/styles/colors";
import { device } from "../../../assets/styles/size";

export const SliderWrapper = styled.div`
	position: relative;
`;
export const Slider = styled.div`
	overflow: hidden;
	margin: 0 18px;
	width: calc(100% - 36px);
`;
export const SliderTrack = styled.div`
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: none;
	overflow-x: scroll;
	overflow-y: hidden;
	display: flex;
	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}
	> div {
		position: relative;
		z-index: 0;
		max-width: ${(props) =>
			props.slidesToShow ? `${100 / props.slidesToShow}%` : "16.6666%"};
		flex: ${(props) =>
			props.slidesToShow ? `0 0 ${100 / props.slidesToShow}%` : "16.6666%"};
	}
`;

export const SliderCustomArrow = styled.div`
	top: 0;
	width: 1px;
	height: 105px;
	display: flex !important;
	align-items: center;
	position: absolute;
	cursor: pointer;
	z-index: 10;
	background-color: ${colors.white};
	transition: opacity 0.3s ease, visibility 0.3s ease;
	@media ${device.tablet} {
		width: 20px;
	}
	${(props) =>
		props.position === "next"
			? css`
					right: 0;
			  `
			: css`
					left: 0;
			  `};

	&:before {
		content: "";
		position: absolute;
		z-index: 1;
		top: 0;
		${(props) =>
			props.position === "next"
				? css`
						left: 0;
				  `
				: css`
						right: 0;
				  `};
		width: 1px;
		height: 100%;
		background-color: ${colors.gray};
	}

	span {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		display: block;
		border-style: solid;
		border-color: ${colors.black};
		border-width: 0 1px 1px 0;
		padding: 4px;
		width: 0;
		height: 0;
		@media ${device.laptopS} {
			padding: 6px;
		}
		${(props) =>
			props.position === "next"
				? css`
						transform: rotate(-45deg);
						left: 8px;
						@media ${device.tablet} {
							left: 18px;
						}
				  `
				: css`
						transform: rotate(135deg);
						right: 8px;
						@media ${device.tablet} {
							right: 18px;
						}
				  `};
	}

	${(props) =>
		props.disabled &&
		css`
			visibility: hidden;
			opacity: 0;
		`};
`;
