import React from "react";
import { Wrapper } from "./styled";
import PropTypes from "prop-types";

const CarModelItem = (props) => {
	const {
		active,
		onClick,
		title,
		desc,
		image,
		imageHover,
		factorImg,
		dataModelRange,
		dataModelName,
		dataModelInfo,
		dataModelPrice,
	} = props;
	return (
		<Wrapper
			active={active}
			onClick={onClick}
			factorImg={factorImg}
			data-modelrange={dataModelRange}
			data-modelname={dataModelName}
			data-modelinfo={dataModelInfo}
			data-modelprice={dataModelPrice}
		>
			<figure>
				<img src={image} alt={title} />
				{imageHover && <img src={imageHover} alt={title} />}
			</figure>
			<div>
				<h4>{title}</h4>
				<p>{desc}</p>
			</div>
		</Wrapper>
	);
};

CarModelItem.propTypes = {
	active: PropTypes.bool,
	onClick: PropTypes.func,
	title: PropTypes.string,
	desc: PropTypes.string,
	image: PropTypes.string,
	imageHover: PropTypes.string,
	factorImg: PropTypes.number,
	dataModelRange: PropTypes.string,
	dataModelName: PropTypes.string,
	dataModelInfo: PropTypes.string,
	dataModelPrice: PropTypes.number,
};

CarModelItem.defaultProps = {
	active: false,
	onClick: () => {},
	title: "",
	desc: "",
	image: "",
	imageHover: "",
	factorImg: 1,
	dataModelRange: "",
	dataModelName: "",
	dataModelInfo: "",
	dataModelPrice: "",
};

export default CarModelItem;
