import styled, { css } from "styled-components";
import colors from "../../../assets/styles/colors";
import { device } from "../../../assets/styles/size";

export const Wrapper = styled.div`
	cursor: pointer;
	outline: none !important;
	figure {
		margin: 0 0 8px;
		overflow: hidden;
		position: relative;
		&:before {
			content: "";
			display: block;
			padding-bottom: 50%;
		}
		@media ${device.tablet} {
		}
		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: contain;
			margin: auto;
			transform: scale(
				${(props) => (props.factorImg ? props.factorImg : "0.95")}
			);
			transition: transform 0.3s ease;
			&:nth-of-type(1) {
				display: ${(props) => (props.active ? "none" : "block")};
			}
			&:nth-of-type(2) {
				display: ${(props) => (props.active ? "block" : "none")};
			}
		}
	}

	div {
		text-align: center;
		color: ${colors.black};
		font-weight: 400;
		${(props) =>
			props.active &&
			css`
				font-weight: 700;
				text-decoration: underline;
			`};
		h4 {
			font-size: 14px;
			line-height: 1.2;
			font-weight: inherit;
			margin-top: 0;
			margin-bottom: 4px;
			@media ${device.tablet} {
				font-size: 20px;
				margin-bottom: 8px;
			}
		}

		p {
			font-size: 12px;
			line-height: 1.2;
			margin-top: 0;
			margin-bottom: 0;
			@media ${device.tablet} {
				font-size: 16px;
			}
		}
	}
	&:hover {
		figure {
			img {
				//transform: scale(1);
				transform: scale(
					${(props) => (props.factorImg ? props.factorImg + 0.05 : "1")}
				);
			}
		}
		div {
			font-weight: 700;
			text-decoration: underline;
		}
	}
`;
