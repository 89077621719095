import styled, { css } from "styled-components";
import colors from "../../../assets/styles/colors";
import { device } from "../../../assets/styles/size";
import ReactSelect from "react-select";

export const Wrapper = styled.div`
	opacity: 0;
	visibility: hidden;
	height: 0;
	width: 100%;
	max-width: 460px;
	margin: 0 auto 40px;
	${(props) =>
		props.isActive
			? css`
					color: ${colors.white};
					background-color: ${colors.grayDark2};
			  `
			: css`
					color: ${colors.black};
					background-color: transparent;
			  `};
	transition: background-color 0.3s ease, color 0.3s ease;

	label {
		font-size: 15px;
		line-height: 1;
		display: block;
		padding: 10px 16px 4px;
	}
	@media ${device.tablet} {
		margin: 0 0 70px;
	}
`;

export const StyledCustomSelect = styled(ReactSelect)`
	width: 100%;
	display: inline-block;
	margin-bottom: 0;
	border-bottom: 1px solid ${colors.black};

	.custom-select__control {
		min-height: auto;
		height: 40px;
		background-color: transparent;
		border: none;
		border-radius: 0;

		&.custom-select__control--is-focused {
			border: none;
			outline: none;
			box-shadow: none;
		}

		.custom-select__value-container {
			font-family: "AudiType-Extended";
			font-weight: 700;
			font-size: 20px;
			line-height: 1.25;
			padding: 0 16px;

			.custom-select__single-value {
				color: ${(props) => (props.isOpen ? colors.white : colors.black)};
				transition: color 0.3s ease 0s;
			}
		}

		.custom-select__indicators {
			margin-right: 16px;

			span {
				display: none;
			}

			.custom-select__dropdown-indicator {
				border-style: solid;
				border-width: 0 1px 1px 0;
				padding: 6px;
				width: 0;
				height: 0;
				transition: transform 0.3s ease, border-color 0.3s ease,
					margin-top 0.3s ease;
				${(props) =>
					props.isOpen
						? css`
								margin-top: 5px;
								transform: rotate(-135deg);
								border-color: ${colors.white};
						  `
						: css`
								margin-top: -5px;
								transform: rotate(45deg);
								border-color: ${colors.black};
						  `};

				svg {
					display: none;
				}
			}
		}
	}

	.custom-select__menu {
		background-color: ${colors.grayLight2};
		border: none;
		margin: 4px 0 0;
		border-radius: 0;
		z-index: 20;

		.custom-select__menu-list {
			max-height: 300px;
			padding-top: 0;
			padding-bottom: 0;
			@media ${device.tablet} {
				max-height: 400px;
			}

			.custom-select__group {
				padding-top: 0;
				padding-bottom: 0;

				.custom-select__group-heading {
					color: ${colors.gray};
					font-size: 16px;
					line-height: 1;
					text-transform: none;
					padding: 20px 16px;
				}

				.custom-select__option {
					color: ${colors.black};
					font-size: 16px;
					line-height: 1;
					text-transform: none;
					padding: 20px 16px 20px 32px;
					transition: color 0.3s ease, background-color 0.3s ease;

					&:hover,
					&.custom-select__option--is-focused {
						background-color: ${colors.white};
					}

					&.custom-select__option--is-selected {
						background-color: ${colors.grayDark2};
						color: ${colors.white};
						font-weight: 700;
					}
				}
			}
		}
	}
`;
