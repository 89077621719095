import React, { useState, useRef, useEffect } from "react";
import smoothscroll from "smoothscroll-polyfill";
import {
	SliderWrapper,
	Slider,
	SliderTrack,
	SliderCustomArrow,
} from "./styled";
import CarModelItem from "../CarModelItem";
import { sizeValues } from "../../../assets/styles/size";
import API from "../../../classes/api";

const SliderCustom = ({
	items,
	onItemSelected,
	itemActive,
	setItemsPerRow,
	mode,
}) => {
	const sliderTrackRef = useRef();
	const [itemWidth, setItemWidth] = useState(null);
	const [trackScrollLeft, setTrackScrollLeft] = useState(0);
	const [slidesToShow, setSlidesToShow] = useState(null);
	const [slideCurrentPage, setSlideCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(null);
	const [actionFlag, setActionFlag] = useState(null);

	const changePage = (direction) => {
		setActionFlag("page");
		if (direction === "next") {
			if (slideCurrentPage < totalPages) {
				setSlideCurrentPage((slideCurrentPage) => slideCurrentPage + 1);
			} else {
				moveSliderPage();
			}
		} else {
			if (slideCurrentPage > 1) {
				setSlideCurrentPage((slideCurrentPage) => slideCurrentPage - 1);
			} else {
				moveSliderPage();
			}
		}
	};

	const moveSliderPage = () => {
		const restItems = items.length - slidesToShow * (slideCurrentPage - 1);
		let toScrollLeft;
		if (restItems >= slidesToShow) {
			toScrollLeft = itemWidth * slidesToShow * (slideCurrentPage - 1);
		} else {
			toScrollLeft = (items.length - slidesToShow) * itemWidth;
		}
		sliderTrackRef.current.scrollTo({
			top: 0,
			left: toScrollLeft,
			behavior: "smooth",
		});
	};

	const moveSliderItem = (index, center = false) => {
		const halfSlidesToShow = Math.ceil(slidesToShow / 2);
		let toScrollLeft;
		const currentPage = parseInt(index / slidesToShow) + 1;
		setSlideCurrentPage(currentPage);

		if (index < halfSlidesToShow) {
			// console.log("ITEM START");
			toScrollLeft = 0;
		} else if (
			index >= halfSlidesToShow &&
			index < items.length - halfSlidesToShow
		) {
			// console.log("ITEM MIDDLE");
			toScrollLeft =
				itemWidth * index -
				sliderTrackRef.current.clientWidth / 2 +
				itemWidth / 2;
		} else if (index >= items.length - halfSlidesToShow) {
			// console.log("ITEM END");
			toScrollLeft = itemWidth * (items.length - slidesToShow);
		}
		sliderTrackRef.current.scrollTo({
			top: 0,
			left: toScrollLeft,
			behavior: "smooth",
		});
	};

	const onScrollUpdate = () => {
		//const right = sliderTrackRef.current?.scrollWidth - sliderTrackRef.current?.scrollLeft === sliderTrackRef.current?.clientWidth;
		setActionFlag("scroll");
		const currentScrollLeft = sliderTrackRef.current?.scrollLeft;
		setTrackScrollLeft(currentScrollLeft);
		const pageWidth = slidesToShow * itemWidth;
		const currentPage = parseInt(currentScrollLeft / pageWidth) + 1;
		setSlideCurrentPage(currentPage);
	};

	const resizeListener = () => {
		switch (true) {
			case window.innerWidth <= sizeValues.mobileM:
				setSlidesToShow(1.5);
				setItemsPerRow(1.5);
				break;
			case window.innerWidth > sizeValues.mobileM &&
				window.innerWidth <= sizeValues.mobileLL:
				setSlidesToShow(2.5);
				setItemsPerRow(2.5);
				break;
			case window.innerWidth > sizeValues.mobileLL &&
				window.innerWidth <= sizeValues.tabletL:
				setSlidesToShow(3.5);
				setItemsPerRow(3.5);
				break;
			case window.innerWidth > sizeValues.tabletL &&
				window.innerWidth <= sizeValues.laptop:
				setSlidesToShow(4.5);
				setItemsPerRow(4.5);
				break;
			case window.innerWidth > sizeValues.laptop &&
				window.innerWidth <= sizeValues.laptopL:
				setSlidesToShow(5.5);
				setItemsPerRow(5.5);
				break;
			default:
				setSlidesToShow(6.5);
				setItemsPerRow(6.5);
				break;
		}
	};

	useEffect(() => {
		smoothscroll.polyfill();
		resizeListener();
		window.addEventListener("resize", resizeListener);

		return () => {
			window.removeEventListener("resize", resizeListener);
		};
	}, []);

	useEffect(() => {
		setTotalPages(Math.ceil(items.length / slidesToShow));
		if (actionFlag === "page") moveSliderPage();

		const sliderItem = sliderTrackRef.current.querySelector("div");
		if (sliderItem) setItemWidth(sliderItem.clientWidth);
	}, [slideCurrentPage, slidesToShow, items.length]);

	return (
		<SliderWrapper>
			<SliderCustomArrow
				disabled={trackScrollLeft === 0}
				position="prev"
				onClick={() => {
					changePage("prev");
				}}
			>
				<span />
			</SliderCustomArrow>
			<Slider>
				<SliderTrack
					ref={sliderTrackRef}
					slidesToShow={slidesToShow}
					onScroll={onScrollUpdate}
				>
					{items.map((item, index) => {
						return (
							<CarModelItem
								key={item.id}
								title={`Audi ${item.titulo}`}
								desc={
									mode === "renting-empresa"
										? `Por ${API.formatPrecio(
												API.findMejorOfertaFromCarline(
													item,
													"renting",
												).precio,
												false,
										  )}€/${
												API.findMejorOfertaFromCarline(
													item,
													"renting",
												).periodicidad
										  }`
										: `Desde ${API.formatPrecio(
												item.pvpRecomendado,
												false,
										  )}€`
								}
								image={item.imagenes?.lateral?.s}
								imageHover={item.imagenes?.tresCuartos?.s}
								factorImg={item.imagenes?.escala}
								active={item.id === itemActive}
								onClick={() => {
									setActionFlag("item");
									moveSliderItem(index, true);
									onItemSelected(item);
								}}
								dataModelRange={item.codigo}
							/>
						);
					})}
				</SliderTrack>
			</Slider>
			<SliderCustomArrow
				disabled={
					sliderTrackRef.current?.scrollWidth -
						sliderTrackRef.current?.scrollLeft ===
					sliderTrackRef.current?.clientWidth
				}
				position="next"
				onClick={() => {
					changePage("next");
				}}
			>
				<span />
			</SliderCustomArrow>
		</SliderWrapper>
	);
};

export default SliderCustom;
